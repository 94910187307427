import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import GameplayTask, {
  CompBulkActions,
  EditGameplayTask,
  GameplayTaskValues
} from '../../../types/api/Game/GameplayTask';
import MessageResponse from '../../../types/api/MessageResponse';
import IDropdownResultsByName from '../../../types/api/Admin/ReferralRewards/DropdownResults';

export async function getFilterTasks(
  page: number = 1, perPage: number = 20, {name, types, statisticIds, inventoryClassIds}: GameplayTaskValues
): Promise<AxiosResponse<PaginatedResponse<GameplayTask>>> {
  return makeAdminRequest('POST', '/api/admin/game/gameplay-tasks/filter', {
    page, perPage, name, types,
    statisticIds: statisticIds?.map((item) => item.value),
    inventoryClassIds: inventoryClassIds?.map((item) => item.value),
  });
}

export async function getTaskTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/gameplay-tasks/types');
}

export async function deleteGameplayTask(id: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/gameplay-tasks/${id}`);
}

export async function createTask({
  type, name, description, statistic, goal, weight, subStatistic,
}: EditGameplayTask): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/admin/game/gameplay-tasks', {
    type: type.value, name, description, statisticId: statistic?.value, goal, weight, subStatistic,
  });
}

export async function updateTask({
  id, type, name, description, statistic, goal, weight, subStatistic
}: EditGameplayTask): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', `/api/admin/game/gameplay-tasks/${id}`, {
    type: type.value, name, description, statisticId: statistic?.value, goal, weight, subStatistic,
  });
}

export async function getCompBulkActions(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return makeAdminRequest('GET', '/api/admin/game/gameplay-tasks/compatibilities-bulk-actions');
}

export async function saveCompatibilities(
  gameplayTaskId: string, compatibilities: { inventoryClassId: string, compatible: boolean }[]
) {
  return makeAdminRequest('POST',
    `/api/admin/game/gameplay-tasks/${gameplayTaskId}/compatibilities`,
    {compatibilities});
}

export async function saveCompatibilitiesBulkActions({
  action, ids, compatibilities, repCompatibilities
}: CompBulkActions) {
  return makeAdminRequest('POST', '/api/admin/game/gameplay-tasks/compatibilities-bulk-actions', {
    action, gameplayTaskIds: ids,
    compatibilities: compatibilities ?
      compatibilities.map(item => ({inventoryClassId: item.inventoryClassId?.value, compatible: item.compatible})) :
      repCompatibilities ?
        repCompatibilities.map(item => ({
          inventoryClassId: item.inventoryClassId?.value,
          replaceInventoryClassId: item.replaceInventoryClassId?.value
        })) : undefined,
  });
}

export async function searchGameplayTasksByName(name: string, type?: number | null): Promise<AxiosResponse<IDropdownResultsByName[]>> {
  const params = new URLSearchParams();

  if (name) params.append('name', name);
  if (type !== undefined && type !== null) params.append('type', String(type));

  return makeAdminRequest('GET', `/api/admin/game/gameplay-tasks/search?${params.toString()}`);
}
