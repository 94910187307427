import React, {useState, useEffect} from 'react';

import Field from '../../../ModalUtils/Field/Field';
import TextOrNumberInput from '../../../ModalUtils/TextOrNumberInput/TextOrNumberInput';
import CustomSelect from '../../../ModalUtils/CustomSelect/CustomSelect';
import CustomAsyncSelect from '../../../ModalUtils/CustomAsyncSelect/CustomAsyncSelect';

import BadgeButton from '../../../../Admin/BadgeButton/BadgeButton';
import {Button} from '../../../../common/Buttons/Button';

import {getGameplayTaskTypeByCategory} from '../../../../../types/api/Game/GameplayTask';

import API from '../../../../../api';
import {PERK_TYPES, RarityAcquisition} from '../../../../../types/api/Game/Perk';
import IInventoryCategories from '../../../../../types/api/Admin/Game/InventoryCategories';
import IDropdownResultsByName from '../../../../../types/api/Admin/ReferralRewards/DropdownResults';
import IDropdownResults from '../../../../../types/api/Admin/GameSessions/DropdownResults';
import {BadRequest} from '../../../../../api/errors/errors';

import {ModalFooter, ModalTitle, ModalWrapper} from '../../../ModalUtils/styles';

let inputChangedTimeout: NodeJS.Timeout;
const INPUT_CHANGE_APPLY_MILLIS = 1500; // 1.5 sec

export interface IPredefinedPerk {
  acquisitionRarity: number;
  perkId: string;
  perkName?: string;
  gameplayTaskId: string | null;
  taskName?: string;
  perkRarity: number;
  type: number;
  level?: number;
  points?: number;
  typeName: string;
}

interface ICustomItemsCreation {
  onClose: () => void;
  rarity: number;
  isNft: boolean;
  category: number | null;
  selectedItemId: string;
  rarityTypes: IInventoryCategories[];
  getData: () => Promise<void>;
  submitFunction: (selectedItemId: string, userId: string, predefinedPerks: any[]) => Promise<void>;
  rarityLevels: IInventoryCategories[];
  userId?: string;
}

const CustomItemsCreation = ({
  onClose,
  rarity, isNft, category, selectedItemId,
  rarityTypes,
  getData, submitFunction,
  rarityLevels,
  userId
}: ICustomItemsCreation) => {
  const [user, setUser] = useState<{ value: string, label: string } | null>(null);

  const [powerRarityAcquisitions, setPowerRarityAcquisitions] = useState<RarityAcquisition[]>([]);
  const [valueRarityAcquisitions, setValueRarityAcquisitions] = useState<RarityAcquisition[]>([]);

  const [powerItems, setPowerItems] = useState<IPredefinedPerk[]>([]);
  const [valueItems, setValueItems] = useState<IPredefinedPerk[]>([]);

  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [waitingResponse, setWaitingResponse] = useState<boolean>(true);

  const getRarityAcquisitions = async (type: number) => {
    const response = await API.admin.perks.getRarityAcquisitions(type);
    if (response) {
      if (type === PERK_TYPES.POWER) {
        setPowerRarityAcquisitions(response.data);
      } else {
        setValueRarityAcquisitions(response.data);
      }
    }
  };

  const getUserInfoItem = async () => {
    try {
      const response = await API.admin.inventories.getUserInfoItem(selectedItemId, userId || '');

      if (response.data && response.data?.perksData?.length > 0) {
        setPowerItems((prevPowerItems) =>
          response.data.perksData
            .sort((a, b) => a.acquisitionRarity - b.acquisitionRarity)
            .filter((perk) => perk.type === PERK_TYPES.POWER)
            .map((newPerk) => {
              const existingPerk = prevPowerItems.find((type) => type.acquisitionRarity === newPerk.acquisitionRarity);
              return existingPerk
                ? {
                  ...existingPerk, ...newPerk,
                  taskName: newPerk.taskName ?? existingPerk.taskName,
                  typeName: newPerk.typeName ?? existingPerk.typeName,
                }
                : newPerk;
            })
        );


        setValueItems((prevValueItems) =>
          response.data.perksData
            .sort((a, b) => a.acquisitionRarity - b.acquisitionRarity)
            .filter((perk) => perk.type === PERK_TYPES.VALUE)
            .map((newPerk) => {
              const existingPerk = prevValueItems.find((type) => type.acquisitionRarity === newPerk.acquisitionRarity);
              return existingPerk
                ? {
                  ...existingPerk, ...newPerk,
                  taskName: newPerk.taskName ?? existingPerk.taskName,
                  typeName: newPerk.typeName ?? existingPerk.typeName
                }
                : newPerk;
            })
        );
      }
    } catch (error) {
      console.error('Error fetching user info items:', error);
    }
  };


  useEffect(() => {
    getRarityAcquisitions(PERK_TYPES.POWER).catch(console.error);
    if (isNft) {
      getRarityAcquisitions(PERK_TYPES.VALUE).catch(console.error);
    }
  }, [isNft]);

  const handleInputChange = (index: number, field: string, value: any, isValueColumn: boolean) => {
    const setItems = isValueColumn ? setValueItems : setPowerItems;
    setItems(prevItems => {
      const updatedItems = [...prevItems];
      updatedItems[index] = {...updatedItems[index], [field]: value};
      return updatedItems;
    });
  };

  const parseData = (data: (IDropdownResultsByName | IDropdownResults)[]) => {
    return data.map(item => ({
      value: item.id,
      label: 'name' in item ? item.name : item.email
    }));
  };

  const loadPerkOptions = (inputValue: string, type: number, category: number | null, callback: (values: any[]) => void) => {
    if (!inputValue) {
      return;
    }
    if (inputChangedTimeout) {
      clearTimeout(inputChangedTimeout);
    }
    inputChangedTimeout = setTimeout(async () => {
      const response = await API.admin.perks.searchPerksByName(inputValue, type, category);
      if (!response) {
        return;
      }
      callback(parseData(response.data));
    }, INPUT_CHANGE_APPLY_MILLIS);
  };

  const loadGameplayTaskOptions = (inputValue: string, type: number | null, callback: (values: any[]) => void) => {
    if (!inputValue) {
      return;
    }
    if (inputChangedTimeout) {
      clearTimeout(inputChangedTimeout);
    }
    inputChangedTimeout = setTimeout(async () => {
      const response = await API.admin.gameplayTasks.searchGameplayTasksByName(inputValue, type);
      if (!response) {
        return;
      }
      callback(parseData(response.data));
    }, INPUT_CHANGE_APPLY_MILLIS);
  };

  const loadOptions = (inputValue: string, callback: (values: { value: string, label: string }[]) => void) => {
    if (!inputValue) {
      return;
    }
    if (inputChangedTimeout) {
      clearTimeout(inputChangedTimeout);
    }
    inputChangedTimeout = setTimeout(async () => {
      let response = await API.admin.users.searchByEmail(inputValue);
      if (!response) {
        return;
      }
      callback(parseData(response.data));
    }, INPUT_CHANGE_APPLY_MILLIS);
  };

  const submit = async () => {
    setWaitingResponse(false);

    let predefinedPerks;

    const power = powerItems
      .filter(({typeName}) => typeName === 'PERK_GRANT')
      .map(({perkName, gameplayTaskId, taskName, typeName, ...rest}) => rest);
    const value = valueItems
      .filter(({typeName}) => typeName === 'PERK_GRANT')
      .map(({perkName, taskName, typeName, ...rest}) => rest);

    if (isNft) {
      predefinedPerks = [...power, ...value];
    } else {
      predefinedPerks = [...power];
    }

    try {
      if (userId || user) {
        await submitFunction(selectedItemId, (userId || user?.value || ''), predefinedPerks);
      }
      await getData();
      onClose();
      window.addToast('success', '', 'Data updated', 1500, '#1FBF42');
    } catch (e: any) {
      if (e instanceof BadRequest) {
        return;
      }
      window.addToast('danger', 'Error', `${e ? e.message : 'Failed. Please try again later'}`);
    } finally {
      setWaitingResponse(true);
    }
  };

  useEffect(() => {
    const processItems = (rarityAcquisitions: RarityAcquisition[]) => {
      const filtered = rarityAcquisitions.filter(item => item.rarity <= rarity);

      const result: IPredefinedPerk[] = filtered
        .map(item => ({
          acquisitionRarity: item.rarity,
          perkId: '',
          perkName: '',
          perkRarity: 0,
          type: item.perkType,
          typeName: item.typeName,
          points: undefined,
          level: undefined,
          gameplayTaskId: item.perkType === PERK_TYPES.VALUE ? '' : null,
          taskName: item.perkType === PERK_TYPES.VALUE ? '' : undefined,
        }))
        .sort((a, b) => a.acquisitionRarity - b.acquisitionRarity);

      for (let i = 0; i < result.length; i++) {
        if (result[i].typeName === 'PERK_UPGRADE') {
          for (let j = i - 1; j >= 0; j--) {
            if (result[j].typeName === 'PERK_GRANT' && result[j].level === undefined) {
              result[j].level = 1;
              break;
            }
          }
        }
      }

      return result;
    };

    if (powerRarityAcquisitions.length > 0) {
      setPowerItems(processItems(powerRarityAcquisitions));
    }
    if (isNft && valueRarityAcquisitions.length > 0) {
      setValueItems(processItems(valueRarityAcquisitions));
    }

    if (userId) {
      getUserInfoItem().catch(console.error);
    }
  }, [powerRarityAcquisitions, valueRarityAcquisitions, rarity, isNft, userId]);

  useEffect(() => {
    const checkPerks = (perks: IPredefinedPerk[], type: PERK_TYPES): boolean => {
      return perks
        .filter(perk => perk.typeName === 'PERK_GRANT')
        .every(perk => {
          if (type === PERK_TYPES.POWER) {
            return Boolean(perk.perkId && perk.perkName);
          } else {
            return Boolean(perk.perkId && perk.perkName && perk.gameplayTaskId && perk.taskName);
          }
        });
    };

    if (checkPerks(powerItems, PERK_TYPES.POWER) && checkPerks(valueItems,
      PERK_TYPES.VALUE) && (userId || user?.value)) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [userId, user, powerItems, valueItems]);

  const renderItems = (items: IPredefinedPerk[], isValueColumn: boolean) => {
    return items.map((item, index) => (
      <>
        {item.typeName === 'PERK_GRANT' && (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '300px',
              border: '1px solid #FFA500',
              borderRadius: '12px',
              padding: '16px',
              gap: 12,
            }}
          >
            <div>
              <Field title="Rarity of acquisition:" style={{margin: 0}}>
                <></>
              </Field>
              <BadgeButton
                rarity={{
                  value: item.acquisitionRarity,
                  label: rarityTypes.find(rarityType => rarityType.id === item.acquisitionRarity)?.name || ''
                }}
              />
            </div>

            <Field title="Perk search by Name:" style={{margin: 0}}>
              <CustomAsyncSelect
                loadOptions={(inputValue, callback) => (
                  loadPerkOptions(
                    inputValue,
                    isValueColumn ? PERK_TYPES.VALUE : PERK_TYPES.POWER,
                    category ?? null,
                    callback
                  )
                )}
                value={item.perkId ? {value: item.perkId ?? '', label: item.perkName ?? ''} : undefined}
                onChange={(e) => {
                  handleInputChange(index, 'perkId', e.value, isValueColumn);
                  handleInputChange(index, 'perkName', e.label, isValueColumn);
                }}
                marginBottom={0}
              />
            </Field>

            {isValueColumn && (
              <Field title="Gameplay task search:" style={{margin: 0}}>
                <CustomAsyncSelect
                  loadOptions={(inputValue, callback) => (
                    loadGameplayTaskOptions(
                      inputValue,
                      category !== null ? getGameplayTaskTypeByCategory(category) : null,
                      callback
                    )
                  )}
                  value={item.gameplayTaskId ?
                    {value: item.gameplayTaskId ?? '', label: item.taskName ?? ''} : undefined}
                  onChange={(e) => {
                    handleInputChange(index, 'gameplayTaskId', e.value, isValueColumn);
                    handleInputChange(index, 'taskName', e.label, isValueColumn);
                  }}
                  marginBottom={0}
                />
              </Field>
            )}

            <Field title="Rarity:" style={{margin: 0}}>
              <CustomSelect
                options={rarityTypes.map((rarity) => ({value: rarity?.id ?? 0, label: rarity.name}))}
                onChange={(e) => handleInputChange(index, 'perkRarity', Number(e.value), isValueColumn)}
                value={{
                  value: item.perkRarity,
                  label: rarityTypes.find(rarityType => rarityType.id === item.perkRarity)?.name || ''
                }}
                placeholder="Rarity ..."
                style={{margin: 0}}
              />
            </Field>

            {item.level !== undefined && (
              <Field title="Level:" style={{margin: 0}}>
                <CustomSelect
                  options={rarityLevels.map((rarity) => ({value: rarity?.id ?? 0, label: rarity.name}))}
                  onChange={(e) => handleInputChange(index, 'level', Number(e.value), isValueColumn)}
                  value={{
                    value: item.level,
                    label: rarityLevels.find(rarityType => rarityType.id === item.level)?.name || ''
                  }}
                  placeholder="Level ..."
                  style={{margin: 0}}
                />
              </Field>
            )}

            <Field title="Points:" style={{marginBottom: 0}}>
              <TextOrNumberInput
                value={item.points}
                onChange={(e) => handleInputChange(
                  index,
                  'points',
                  e.target.value ? Number(e.target.value) : undefined,
                  isValueColumn
                )}
                type="number"
                placeholder="Points ..."
              />
            </Field>
          </div>
        )}
      </>
    ));
  };

  return (
    <ModalWrapper>
      <ModalTitle className="title-bold">Custom Items Creation</ModalTitle>
      {!userId && (
        <Field title="Email:" style={{width: '335px'}}>
          <CustomAsyncSelect
            loadOptions={loadOptions}
            value={user ?? undefined}
            onChange={(newValue) => setUser(newValue ?? null)}
            activeFocus={true}
          />
        </Field>
      )}

      <div style={{display: 'flex', gap: '32px'}}>
        <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
          <div style={{textAlign: 'center', textTransform: 'uppercase'}}>Power Perks</div>
          <div style={{
            display: 'flex', flexDirection: 'column', gap: 16,
            border: '1px solid #FFA500',
            borderRadius: '12px',
            padding: '16px',
            width: '100%'
          }}>
            {renderItems(powerItems, false)}
          </div>
        </div>
        {isNft && (
          <div style={{display: 'flex', flexDirection: 'column', gap: '16px'}}>
            <div style={{textAlign: 'center', textTransform: 'uppercase'}}>Value Perks</div>
            <div style={{
              display: 'flex', flexDirection: 'column', gap: 16,
              border: '1px solid #FFA500',
              borderRadius: '12px',
              padding: '16px',
              width: '100%'
            }}>
              {renderItems(valueItems, true)}
            </div>
          </div>
        )}
      </div>

      <ModalFooter style={{marginTop: '20px'}}>
        <Button
          buttonText="Cancel"
          onClick={onClose}
          buttonStyle={{marginRight: '20px'}}
        />
        <Button
          buttonText="Save"
          onClick={submit}
          isDisabledButton={buttonDisabled}
          activeLoader={!waitingResponse}
        />
      </ModalFooter>
    </ModalWrapper>
  );
};

export default CustomItemsCreation;
