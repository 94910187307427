export default interface Perk {
  id: string,
  balanceParameterId: string,
  name: string,
  description: string,
  category: number,
  rarity: number,
  type: number,
  weight: number,
  value: number,
  inverted: boolean,
  createdAt: number,
  updatedAt: number,
  compatibilities: Compatibility[],
  typeName: string,
  categoryName: string,
  characterAttributes: CharacterAttribute[],
  percentage: boolean,
  currency?: number,
  limitationCharacterAttributeId: string | null,
  limitationCharacterAttribute: { id: string, name: string, description: string } | null,
  rarityPoints?: [number, number][] | null,
}

export interface Compatibility {
  perkId: string,
  inventoryClassId: string,
  inventoryClassName: string,
  compatible: boolean,
}

export interface CharacterAttribute {
  id: string,
  name: string,
  description: string,
  value: number,
  inverted: boolean,
  additive: boolean,
  entity?: string,
  percentage: boolean,
}

export interface PerkValues {
  name: string,
  type: { id: number | null, label: string },
  categories: number[],
  inventoryClassIds: number[],
}


export interface RarityAcquisition {
  rarity: number,
  perkType: number,
  perkTypeName: string,
  type: number,
  typeName: string,
  probabilities: number[][],
  boostedProbabilities: number[][],
  createdAt: number,
  updatedAt: number,
  rarityName: string,
}

export interface EditPerk {
  type: { value: number, label: string },
  category: { value: number, label: string },
  characterAttributes: EditingCharacterAttribute[],
  characterValues?: { value: string, label: string }[],
  name: string,
  description: string,
  weight: number,
  currency?: number,
  limitationCharacterAttributeId: string | null,
  limitationCharacterAttribute: { id: string, name: string, description: string } | null,
  rarityPoints?: [number, number][] | null,
}

export interface EditingCharacterAttribute {
  characterAttributeId: string,
  inverted: boolean,
  additive: boolean,
  entity?: string | null,
}

export enum PERK_TYPES {
  POWER,
  VALUE
}

export enum PERK_INVENTORY_CATEGORIES {
  VR,
  FS,
  BOTH
}