import React from 'react';

import {Wrapper} from './styles';

interface IBadgeButton {
    rarity?: { value: number, label: string };
    color?: string;
}

const BadgeButton = ({rarity = {value: 0, label: 'COMMON'}, color = ''}: IBadgeButton) => {
  const assignColor = (value: number): string => {
    switch (value) {
    case 0:
      return '#B0B0D9, #4D5A81';
    case 1:
      return '#0DDD75, #045D2D';
    case 2:
      return '#35ABFF, #00569B';
    case 3:
      return '#874EEF, #30018B';
    case 4:
      return '#F37806, #AE3F00';
    case 5:
      return '#F2BD0C, #C07401';
    case 6:
      return '#FF547C, #AC0235';
    case 7:
      return '#C604FF, #8800AC';
    case 8:
      return '#E80710, #850000';
    case 9:
      return '#FF70F6, #D711AC';
    default:
      return '#FFFFFF, #FFFFFF';
    }
  }

  const badgeColor: string = assignColor(rarity?.value);

  return (
    <Wrapper gradient={badgeColor} color={color}>
      {rarity?.label ?? 'COMMON'}
    </Wrapper>
  );
};

export default BadgeButton;