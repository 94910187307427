import React, {useEffect, useState} from 'react';
import {CButton} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {cilCheck, cilX} from '@coreui/icons';

import Modal from '../../../Modal';
import UserInventory
  from '../../../Admin/common/UserInfo/UserInventory/UserInventory';
import UserTournaments
  from '../../../Admin/common/UserInfo/UserTournaments/UserTournaments';
import UserActivityEvents
  from '../../../Admin/common/UserInfo/UserActivityEvents/UserActivityEvents';

import Loader from '../../../../../components/common/Loader/ContainerLoader';

import API from '../../../../../api';
import UserInfoById from '../../../../../types/api/Admin/Users/UserInfoById';
import IInventoryCategories from '../../../../../types/api/Admin/Game/InventoryCategories';
import IInventoryTypes from '../../../../../types/api/Admin/Game/InventoryTypes';

import AlphaKeyStatusGreen from '../../../../../assets/images/admin/AlphaKeyRequests/AlphaKeyStatusGreen.svg';
import AlphaKeyStatusAssigned from '../../../../../assets/images/admin/AlphaKeyRequests/AlphaKeyStatusAssigned.svg';
import AlphaKeyStatusNotGranted from '../../../../../assets/images/admin/AlphaKeyRequests/AlphaKeyStatusNotGranted.svg';

import {ModalWrapper, ModalTitle, ModalText, ModalRow, ModalColumn} from '../../../ModalUtils/styles';

interface IUserInfo {
  onClose: () => void;
  userId: string;
  inventoryTypes: IInventoryTypes[];
}

const UserInfo = ({onClose, userId, inventoryTypes}: IUserInfo) => {
  const [userInfo, setUserInfo] = useState<UserInfoById | null>(null);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [rarityTypes, setRarityTypes] = useState<IInventoryCategories[]>([]);
  const [sectionTypes, setSectionTypes] = useState<IInventoryCategories[]>([]);
  const [rarityLevels, setRarityLevels] = useState<IInventoryCategories[]>([]);

  const [inventoryModalVisible, setInventoryModalVisible] = useState<boolean>(false);
  const [tournamentModalVisible, setTournamentModalVisible] = useState<boolean>(false);
  const [activityEventsModalVisible, setActivityEventsModalVisible] = useState<boolean>(false);

  const getData = async () => {
    const response = await API.admin.users.getUserInfo(userId);
    if (!response) {
      return;
    }
    setUserInfo(response.data);
    setLoaded(true);
  };

  const getRarityTypes = async () => {
    const response = await API.admin.inventories.getRarityTypes();
    if (response) {
      setRarityTypes(response.data);
    }
  };

  const getSectionTypes = async () => {
    const response = await API.admin.inventoryTypes.getInventorySections();
    if (response) {
      setSectionTypes(response.data);
    }
  };

  const getPerkLevels = async () => {
    const response = await API.admin.perks.getRarityLevels();
    if (response) {
      setRarityLevels(response.data);
    }
  };

  const alphaKeyUserInfo = (alphaKeyRequests: number): string => {
    if (alphaKeyRequests === 2) {
      return AlphaKeyStatusGreen;
    } else if (alphaKeyRequests === 1) {
      return AlphaKeyStatusAssigned;
    } else {
      return AlphaKeyStatusNotGranted;
    }
  };

  useEffect(() => {
    getData().catch(console.error);
    getRarityTypes().catch(console.error);
    getSectionTypes().catch(console.error);
    getPerkLevels().catch(console.error);
  }, []);

  return (
    <ModalWrapper>
      <ModalTitle className="title-bold">User: {userInfo?.username || ''}</ModalTitle>
      {loaded ? <ModalRow marginBottom={0} style={{alignItems: 'flex-start'}}>
        <ModalColumn marginBottom={0} style={{width: '100%', minWidth: '450px', overflowX: 'auto'}}>
          <ModalText><b>ID:</b> {userInfo?.id || ''}</ModalText>
          <ModalText><b>Email:</b> {userInfo?.email || ''}</ModalText>
          <ModalText>
            <b>Alpha Key Request:</b>
            {userInfo?.alphaKey ? (
              <CIcon icon={cilCheck} style={{color: 'green'}}/>
            ) : (
              <CIcon icon={cilX} style={{color: 'red'}}/>
            )}
          </ModalText>
          <ModalText><b>Alpha Key:</b>
            <img
              src={alphaKeyUserInfo(userInfo?.alphaKey || 0)} alt=" "
              style={{width: '30px', height: '40px'}}
              title={userInfo?.alphaKey === 2 ? 'Claimed' : (userInfo?.alphaKey === 1 ? 'Granted' : 'Not Granted')}
            />
          </ModalText>
          <ModalText><b>Game Logins:</b> {userInfo?.gameSessions || 0}</ModalText>
          <ModalText>
            <b>Matches participated (VR/FS):</b> {userInfo?.matchesVr || 0} / {userInfo?.matchesFs || 0}
          </ModalText>
          <ModalText>
            <b>Matchtime duration (VR/FS)
              minutes: </b> {userInfo?.matchesVrTime ? userInfo.matchesVrTime / 1000 : 0} / {userInfo?.matchesFsTime ? userInfo.matchesFsTime / 1000 : 0}
          </ModalText>
        </ModalColumn>
        <ModalColumn marginBottom={0} style={{alignItems: 'flex-end', justifyContent: 'flex-start'}}>
          <CButton
            onClick={(event) => {
              event.stopPropagation();
              setInventoryModalVisible(true);
            }}
            color="warning" size="sm"
            style={{marginBottom: '20px', width: '135px'}}
          >
            INVENTORY
          </CButton>
          <CButton
            onClick={(event) => {
              event.stopPropagation();
              setTournamentModalVisible(true);
            }}
            color="warning" size="sm"
            style={{marginBottom: '20px', width: '135px'}}
          >
            TOURNAMENTS
          </CButton>
          <CButton
            onClick={(event) => {
              event.stopPropagation();
              setActivityEventsModalVisible(true);
            }}
            color="warning" size="sm" style={{width: '135px'}}
          >
            ACTIVITY EVENTS
          </CButton>
        </ModalColumn>
      </ModalRow> : <Loader/>}

      {userInfo && <Modal
        isOpen={inventoryModalVisible}
        onClose={() => setInventoryModalVisible(false)}
      >
        <UserInventory
          userInfo={userInfo}
          userId={userId}
          rarityTypes={rarityTypes}
          sectionTypes={sectionTypes}
          rarityLevels={rarityLevels}
          inventoryTypes={inventoryTypes}
        />
      </Modal>}

      {userInfo && <Modal
        isOpen={tournamentModalVisible}
        onClose={() => setTournamentModalVisible(false)}
      >
        <UserTournaments
          userInfo={userInfo}
          userId={userId}
        />
      </Modal>}

      {userInfo && <Modal
        isOpen={activityEventsModalVisible}
        onClose={() => setActivityEventsModalVisible(false)}
      >
        <UserActivityEvents
          userInfo={userInfo}
          userId={userId}
        />
      </Modal>}
    </ModalWrapper>
  );
};

export default UserInfo;
