import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import UserInformation, {FilterBodyRequest} from '../../../types/api/Admin/Users/UserInformation';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import UserOptions from '../../../types/api/Admin/Users/UserOptions';
import MessageResponse from '../../../types/api/MessageResponse';
import UserFiltersSelected from '../../../types/api/Admin/Users/UserFiltersSelected';
import {USER_FILTERS, USER_FILTERS_NAMES} from '../../../types/api/constants';
import IDropdownResults from '../../../types/api/Admin/GameSessions/DropdownResults';
import UserInfoById from '../../../types/api/Admin/Users/UserInfoById';
import ITwoFA from '../../../types/api/Admin/Users/TwoFA';

export async function filterList(filters: FilterBodyRequest): Promise<AxiosResponse<PaginatedResponse<UserInformation>>> {
  return makeAdminRequest('POST', '/api/admin/users/filter', filters);
}

export async function searchByEmail(email: string): Promise<AxiosResponse<IDropdownResults[]>> {
  return makeAdminRequest('GET', `/api/admin/users/dropdown-results?email=${email}`);
}

export async function emailsByUserIds(usersIds: string[]): Promise<AxiosResponse<IDropdownResults[]>> {
  return makeAdminRequest('GET', `/api/admin/users/dropdown-results?userIds=${usersIds}`);
}

async function listFiltered(
  page: number = 1,
  perPage: number = 20,
  filters: UserFiltersSelected,
): Promise<AxiosResponse<PaginatedResponse<UserInformation>>> {
  const body: {
    [key: string]: string[];
  } = {};
  for (const key of Object.keys(filters)) {
    // @ts-ignore
    if (!USER_FILTERS[key]) {
      continue;
    }
    // @ts-ignore
    body[USER_FILTERS_NAMES[USER_FILTERS[key]]] = filters[key];
  }

  return makeAdminRequest(
    'POST',
    `/api/admin/users/filter?page=${page}&perPage=${perPage}`,
    body,
  );
}

export async function getUserStatuses(): Promise<AxiosResponse<UserOptions[]>> {
  return makeAdminRequest('GET', '/api/admin/users/statuses');
}

export async function updateUser(
  userId: string,
  {
    statusId,
    rolesToAdd = [],
    userRolesToDrop = [],
    accessType,
  }: {
    statusId?: string;
    rolesToAdd: string[];
    userRolesToDrop: string[];
    accessType?: number
  },
): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', `/api/admin/users/${userId}`, {
    statusId,
    rolesToAdd,
    userRolesToDrop,
    accessType,
  });
}

export async function deleteUser(userId: string): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('DELETE', `/api/admin/users/${userId}`);
}

export async function getAccessTypes() {
  return await makeAdminRequest('GET', '/api/admin/game/accounts/access-types');
}

export async function getAll(): Promise<AxiosResponse<UserOptions[]>> {
  return makeAdminRequest('GET', '/api/admin/roles/all');
}

export async function getUserInfo(userId: string): Promise<AxiosResponse<UserInfoById>> {
  return makeAdminRequest('GET', `/api/admin/users/${userId}/info`);
}

export async function startBinding2FA(): Promise<AxiosResponse<ITwoFA>> {
  return makeAdminRequest('POST', '/api/admin/sessions/2-fa/request');
}

export async function confirmBinding2FA(token: string, bindingToken: string): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/admin/sessions/2-fa/confirm', {token, bindingToken});
}

export async function create2FASession(token: string) {
  return makeAdminRequest('POST', '/api/admin/sessions/2-fa/create', {token});
}

export async function test2FA() {
  return makeAdminRequest('GET', '/api/admin/sessions/2-fa/test',
  );
}
