import makeAdminRequest from '../../adminRequest';
import {AxiosResponse} from 'axios';
import {IEditReferralRewardBody, IRewardAdmin} from '../../../types/api/Reward';
import PaginatedResponse from '../../../types/api/PaginatedResponse';
import ItemTypes from '../../../types/api/Admin/ItemTypes';

export async function getReferralRewards(): Promise<AxiosResponse<PaginatedResponse<IRewardAdmin>>> {
  return makeAdminRequest('GET', '/api/admin/referral-rewards');
}

export async function getRewardTypes(): Promise<AxiosResponse<ItemTypes[]>> {
  return makeAdminRequest('GET', '/api/admin/referral-rewards/types');
}

export async function getRewardingTypes(): Promise<AxiosResponse<ItemTypes[]>> {
  return makeAdminRequest('GET', '/api/admin/referral-rewards/rewarding-types');
}

export async function destroy(id: string) {
  return makeAdminRequest('DELETE', `/api/admin/referral-rewards/${id}`);
}

export async function create({
  type, rewardingType, order, settings, rewardingSettings
}: IEditReferralRewardBody) {
  return makeAdminRequest('POST', '/api/admin/referral-rewards',
    {type, rewardingType, order, settings, rewardingSettings}
  );
}

export async function update(id: string, {
  type, rewardingType, order, settings, rewardingSettings
}: IEditReferralRewardBody) {
  return makeAdminRequest('POST', `/api/admin/referral-rewards/${id}`,
    {type, rewardingType, order, settings, rewardingSettings}
  );
}