import React, {useEffect, useRef, CSSProperties} from 'react';
import Select, {SingleValue} from 'react-select';

import {MISSION_TYPES} from '../../../../pages/Client/Main/Dashboard/Dashboard';

import {ModalRow} from '../styles';
import '../../../../pages/Admin/index.css';

interface ICustomSelect {
    options: { value: string | MISSION_TYPES | number | null, label: string }[];
    onChange: (value: SingleValue<{ value: string, label: string }> | any) => void;
    defaultValue?: { value: string, label: string } | any;
    value?: { value: string, label: string } | any;
    isMulti?: boolean;
    placeholder?: string;
    activeFocus?: boolean;
    style?: CSSProperties;
    isDisabled?: boolean;
    closeMenuOnSelect?: boolean;
    isClearable?: boolean;
}

const CustomSelect = ({
  options,
  onChange,
  defaultValue,
  value,
  isMulti = false,
  placeholder,
  activeFocus = false,
  style,
  isDisabled,
  closeMenuOnSelect = true,
  isClearable = false,
}: ICustomSelect) => {
  const selectRef = useRef<HTMLSelectElement | any>(null);

  useEffect(() => {
    if (selectRef.current && activeFocus) {
      selectRef.current.focus();
    }
  }, [activeFocus]);

  return (
    <ModalRow style={style}>
      <Select
        options={options}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        isMulti={isMulti}
        placeholder={placeholder}
        ref={selectRef}
        isDisabled={isDisabled}
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={isClearable}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 5,
          }),
          container: (provided) => ({
            ...provided,
            width: '100%',
          }),
          option: (provided) => ({
            ...provided,
            color: 'black',
          }),
        }}
      />
    </ModalRow>
  );
};

export default CustomSelect;