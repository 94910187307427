export default interface GameplayTask {
  id: string,
  type: number,
  statisticId: string,
  name: string,
  description: string,
  goal: number,
  weight: number,
  createdAt: number,
  updatedAt: number,
  compatibilities: Compatibility[],
  typeName: string,
  statisticName: string,
  subStatistic: string | null,
}

export interface Compatibility {
  inventoryClassId: string,
  inventoryClassName: string,
  compatible: boolean,
}

export interface GameplayTaskValues {
  name?: string,
  types?: number[],
  statisticIds?: { value: string, label: string }[],
  inventoryClassIds?: { value: string, label: string }[],
}

export interface EditGameplayTask {
  id?: string,
  type: { value: number, label: string },
  name: string,
  description: string,
  statistic: { value: string, label: string } | null,
  goal: number,
  weight: number,
  subStatistic: string | null,
}

export interface CompBulkActions {
  action: number,
  ids: string[],
  compatibilities?: {
    inventoryClassId: { value: string, label: string },
    compatible: boolean,
  }[],
  repCompatibilities?: {
    inventoryClassId: { value: string, label: string } | null,
    replaceInventoryClassId: { value: string, label: string } | null,
    compatible: boolean,
  }[],
  withComp?: boolean
}

export enum GAMEPLAY_TASK_TYPES {
  FS,
  VR
}

export enum INVENTORY_CATEGORIES {
  VR,
  FS
}

const categoryToGameplayTaskTypeMapping: {
  // [key: INVENTORY_CATEGORIES]: GAMEPLAY_TASK_TYPES
  [key: number]: GAMEPLAY_TASK_TYPES
} = {
  [INVENTORY_CATEGORIES.FS]: GAMEPLAY_TASK_TYPES.FS,
  [INVENTORY_CATEGORIES.VR]: GAMEPLAY_TASK_TYPES.VR,
};


export const getGameplayTaskTypeByCategory = (category: INVENTORY_CATEGORIES): GAMEPLAY_TASK_TYPES => {
  return categoryToGameplayTaskTypeMapping[category];
};