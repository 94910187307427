import makeAdminRequest from '../../adminRequest';
import axios, {AxiosError, AxiosResponse} from 'axios';

import error from '../../errors';

import config from '../../config';
import {AUTH_SESSION_NAME, BANK_SESSION_NAME} from '../../../config';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import Collection, {
  CollectionValues,
  ICraftSettings,
  ICraftSettingsBody, IDismantleSettings, IDismantleSettingsBody
} from '../../../types/api/Admin/Collections/Collection';
import {ClassBulkActions, IAttributes} from '../../../types/api/Admin/Game/Inventories';
import InventoryClass from '../../../types/api/Admin/InventoryClasses/InventoryClasses';
import MessageResponse from '../../../types/api/MessageResponse';

export async function create(
  name: string,
  inventoryTypeId: string,
  rarity: number,
  archetypeId: string,
  dismantable: boolean,
  fromCommonInventoryId: string | null,
  inventoryKindId?: string,
): Promise<AxiosResponse<Collection>> {
  return makeAdminRequest('POST', '/api/banking/admin/collections', {
    name,
    inventoryTypeId,
    rarity,
    archetypeId,
    dismantable,
    fromCommonInventoryId,
    inventoryKindId,
  });
}

export async function update(
  collectionId: string,
  name: string,
  inventoryTypeId: string,
  rarity: number,
  dismantable: boolean,
  fromCommonInventoryId: string | null,
  inventoryKindId?: string,
  archetypeId?: string,
): Promise<AxiosResponse<{ message: string }>> {
  return makeAdminRequest('POST', `/api/banking/admin/collections/${collectionId}`, {
    name,
    inventoryTypeId,
    rarity,
    dismantable,
    fromCommonInventoryId,
    inventoryKindId,
    archetypeId,
  });
}

export async function updateCollectionImage(
  collectionId: string,
  formData: FormData,
  updateOpenLootImage: boolean = false,
  applyImageToAssets: boolean = false,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/banking/admin/collections/${collectionId}/image?openloot=${updateOpenLootImage}&applyToAssets=${applyImageToAssets}`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function updateCollectionFullScaleImage(
  collectionId: string,
  formData: FormData,
  applyImageToAssets: boolean = false,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/banking/admin/collections/${collectionId}/full-scale-image?applyToAssets=${applyImageToAssets}`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function assignCollectionAsset(
  collectionId: string,
  formData: FormData,
  bank: boolean = false,
) {
  try {
    return await axios({
      method: 'post',
      url: `${config.SERVER_PROTO}://${config.SERVER_URL}/api/banking/admin/collections/${collectionId}/assign-asset`,
      data: formData,
      withCredentials: true,
      xsrfHeaderName: 'authorization',
      xsrfCookieName: bank ? BANK_SESSION_NAME : AUTH_SESSION_NAME,
    });
  } catch (err: AxiosError | any) {
    if (err.isAxiosError) {
      error(err);
    }
    throw err;
  }
}

export async function proceedTreasury(id: string, stakingProgramId: string) {
  return makeAdminRequest('POST', `/api/banking/admin/collections/${id}/treasury`, {
    stakingProgramId,
  });
}

export async function increaseTreasuryPeriod(
  id: string,
  period: number,
  secret: string,
) {
  return makeAdminRequest('POST', `/api/banking/admin/collections/${id}/period`, {
    period,
    secret,
  });
}

export async function getFilterCollections(
  page: number = 1,
  perPage: number = 20,
  {section, inventoryTypes, inventoryKinds, rarities}: CollectionValues,
): Promise<AxiosResponse<PaginatedResponse<Collection>>> {
  return makeAdminRequest('POST', '/api/banking/admin/collections/filter', {
    page,
    perPage,
    section,
    inventoryTypes,
    inventoryKinds,
    rarities
  });
}

export async function syncTask(
  {
    collectionId, useCollectionImage, attributes,
    syncFolder, imagesExtension,
  }: {
    collectionId: string, useCollectionImage?: boolean, attributes?: IAttributes[],
    syncFolder?: string, imagesExtension?: string
  },
) {
  return makeAdminRequest('POST', `/api/banking/admin/collections/${collectionId}/sync-task`, {
    useCollectionImage,
    attributes,
    syncFolder,
    imagesExtension,
  });
}

export async function deleteImage(collectionId: string, fullScale: boolean) {
  return makeAdminRequest('DELETE', `/api/banking/admin/collections/${collectionId}/image`, {fullScale});
}

export async function assignCollectionUserAsset(userId: string, collectionId: string) {
  return makeAdminRequest('POST', `/api/banking/admin/collections/${collectionId}/assign-asset`, {userId});
}

export async function searchCollections(name: string, rarity?: number) {
  if (Number(rarity) >= 0) {
    return makeAdminRequest('GET', `/api/banking/admin/collections/dropdown-results?name=${name}&rarity=${rarity}`);
  } else {
    return makeAdminRequest('GET', `/api/banking/admin/collections/dropdown-results?name=${name}`);
  }
}

export async function getOpenlootRarities(): Promise<AxiosResponse<{ name: string, id: number }[]>> {
  return makeAdminRequest('GET', '/api/banking/admin/openloot/rarities');
}

export async function setCollectionAttributes(collectionId: string, attributes: IAttributes[]) {
  return makeAdminRequest('POST', `/api/banking/admin/collections/${collectionId}/attributes`, {attributes});
}

export async function getClasses(collectionId: string): Promise<AxiosResponse<InventoryClass[]>> {
  return await makeAdminRequest('GET', `/api/admin/game/items-classes/collections/${collectionId}`);
}

export async function addClasses(collectionId: string, classIds: string[]) {
  return await makeAdminRequest('POST', `/api/admin/game/items-classes/collections/${collectionId}`, {classIds});
}

export async function saveBulkActions({id, action, selectedItemId, replaceClassId}: ClassBulkActions) {
  return makeAdminRequest('POST', '/api/admin/game/items-classes/collections-bulk-actions', {
    classId: id, action, collectionIds: selectedItemId, replaceClassId
  });
}

export async function getCraftSettings(): Promise<AxiosResponse<ICraftSettings[]>> {
  return await makeAdminRequest('GET', '/api/banking/admin/collections/craft-settings');
}

export async function setCraftSettings({
  isDefault,
  cost,
  reward,
  collectionId,
  rarity,
  section
}: ICraftSettingsBody): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/banking/admin/collections/craft-settings', {
    isDefault, cost, reward, rarity, section,
    collectionId: isDefault ? undefined : collectionId,
  });
}

export async function deleteSelectedCraftSetting(collectionId: string) {
  return makeAdminRequest('DELETE', `/api/banking/admin/collections/craft-settings/${collectionId}`);
}

export async function deleteDefaultCraftSetting(section: number, rarity: number) {
  return makeAdminRequest('DELETE', '/api/banking/admin/collections/craft-settings/default', {section, rarity});
}

export async function getDismantleSettings(): Promise<AxiosResponse<IDismantleSettings[]>> {
  return await makeAdminRequest('GET', '/api/banking/admin/collections/dismantle-settings');
}

export async function setDismantleSettings({
  isDefault,
  reward,
  collectionId,
  rarity,
  section,
  rewardItems
}: IDismantleSettingsBody): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/banking/admin/collections/dismantle-settings', {
    isDefault, reward, collectionId, rarity, section, rewardItems
  });
}

export async function deleteSelectedDismantleSetting(collectionId: string) {
  return makeAdminRequest('DELETE', `/api/banking/admin/collections/dismantle-settings/${collectionId}`);
}

export async function createItem(collectionId: string, userId: string, predefinedPerks: any[]) {
  return makeAdminRequest('POST',
    `/api/banking/admin/collections/${collectionId}/grant-asset`,
    {userId, predefinedPerks});
}

export async function deleteDefaultDismantleSetting(section: number, rarity: number) {
  return makeAdminRequest('DELETE', '/api/banking/admin/collections/dismantle-settings/default', {section, rarity});
}