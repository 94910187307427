import React from 'react';
import {CButtonGroup, CFormCheck} from '@coreui/react';
import {cilCheck, cilX} from '@coreui/icons';
import CIcon from '@coreui/icons-react';

interface IButtonGroup {
    group: string
    label: string;
    onChange: (value: string) => void;
}

const ButtonGroup = ({group, label, onChange}: IButtonGroup) => {
  const handleInputChange = (value: string) => {
    onChange(value);
  };

  return (
    <CButtonGroup role="group" aria-label="Basic checkbox toggle button group">
      <CFormCheck
        id={`true-${group}`}
        label={<CIcon icon={cilCheck} style={{color: 'green'}}/>}
        checked={label === 'true'}
        onChange={() => handleInputChange('true')}
        name={group}

        type="radio"
        button={{color: 'light', variant: 'outline'}}
      />
      <CFormCheck
        id={`false-${group}`}
        label={<CIcon icon={cilX} style={{color: 'red'}}/>}
        checked={label === 'false'}
        onChange={() => handleInputChange('false')}
        name={group}

        type="radio"
        button={{color: 'light', variant: 'outline'}}
      />
      <CFormCheck
        id={`all-${group}`}
        label='All'
        checked={label === 'all'}
        onChange={() => handleInputChange('all')}
        name={group}

        type="radio"
        button={{color: 'light', variant: 'outline'}}
      />
    </CButtonGroup>
  );
};

export default ButtonGroup;