import {AxiosResponse} from 'axios';

import makeAdminRequest from '../../adminRequest';

import PaginatedResponse from '../../../types/api/PaginatedResponse';
import IInventories, {
  ClassBulkActions,   IDismantleSettings,
  InventoryValues, IDismantleSettingsBody
} from '../../../types/api/Admin/Game/Inventories';
import IUserInventory, {InfoFilterBodyRequest} from '../../../types/api/Admin/Users/UserInventory';
import IDropdownResults from '../../../types/api/Admin/GameSessions/DropdownResults';
import IInventoryCategories from '../../../types/api/Admin/Game/InventoryCategories';
import InventoryClass from '../../../types/api/Admin/InventoryClasses/InventoryClasses';
import MessageResponse from '../../../types/api/MessageResponse';
import {IPredefinedPerk} from '../../../components/Modal/Admin/common/CustomItemsCreation/CustomItemsCreation';

export async function getFilterInventories(
  page: number = 1,
  perPage: number = 20,
  {section, rarities, typeIds, kindIds, classIds, name}: InventoryValues,
): Promise<PaginatedResponse<IInventories>> {
  const response = await makeAdminRequest('POST', '/api/admin/game/inventories/filter', {
    page, perPage, section: section?.id, rarities, typeIds, kindIds, classIds, name,
  });
  return response.data;
}

export async function deleteInventory(inventoryId: string) {
  await makeAdminRequest('DELETE', `/api/admin/game/inventories/${inventoryId}`);
}

export async function grantInventory(userId: string, inventoryId: string, amount?: number) {
  return makeAdminRequest('POST', `/api/admin/game/inventories/${inventoryId}/grant`, {userId, amount});
}

export async function grantInventoryForUserInfo(inventoryId: string, userId: string) {
  return makeAdminRequest('POST', `/api/admin/game/inventories/${inventoryId}/grant`, {userId});
}

export async function getUserInventory(userId: string, filterValues: InfoFilterBodyRequest): Promise<AxiosResponse<PaginatedResponse<IUserInventory>>> {
  return await makeAdminRequest('POST', `/api/admin/game/users/${userId}/inventories/filter`,
    {
      ...filterValues,
      isAsset: filterValues.isAsset !== 'all' ? (filterValues.isAsset === 'true') : undefined
    }
  );
}

export async function searchInventoriesByName(name: string): Promise<AxiosResponse<IDropdownResults[]>> {
  return await makeAdminRequest('GET', `/api/admin/game/inventories/dropdown-results?name=${name}`);
}

export async function deleteUserInventory(userId: string, inventoryId: string) {
  await makeAdminRequest('DELETE', '/api/admin/game/user-inventories', {userId, inventoryId});
}

export async function getRarityTypes(): Promise<AxiosResponse<IInventoryCategories[]>> {
  return await makeAdminRequest('GET', '/api/admin/game/inventories/rarity-types');
}

export async function getClasses(commonInventoryId: string): Promise<AxiosResponse<InventoryClass[]>> {
  return await makeAdminRequest('GET', `/api/admin/game/items-classes/inventories/${commonInventoryId}`);
}

export async function addClasses(commonInventoryId: string, classIds: string[]) {
  return await makeAdminRequest('POST', `/api/admin/game/items-classes/inventories/${commonInventoryId}`, {classIds});
}

export async function saveBulkActions({id, action, selectedItemId, replaceClassId}: ClassBulkActions) {
  return makeAdminRequest('POST', '/api/admin/game/items-classes/inventories-bulk-actions', {
    classId: id, action, inventoryIds: selectedItemId, replaceClassId,
  });
}

export async function getDismantleSettings(): Promise<AxiosResponse<IDismantleSettings[]>> {
  return await makeAdminRequest('GET', '/api/admin/game/inventories/dismantle-settings');
}

export async function setDismantleSettings({
  isDefault,
  reward,
  commonInventoryId,
  rarity,
  section,
}: IDismantleSettingsBody): Promise<AxiosResponse<MessageResponse>> {
  return makeAdminRequest('POST', '/api/admin/game/inventories/dismantle-settings', {
    isDefault, reward, commonInventoryId, rarity, section
  });
}

export async function deleteSelectedDismantleSetting(commonInventoryId: string) {
  return makeAdminRequest('DELETE', `/api/admin/game/inventories/dismantle-settings/${commonInventoryId}`);
}

export async function deleteDefaultDismantleSetting(section: number, rarity: number) {
  return makeAdminRequest('DELETE', '/api/admin/game/inventories/dismantle-settings/default', {section, rarity});
}

export async function createItem(commonInventoryId: string, userId: string, predefinedPerks: any[]) {
  return makeAdminRequest('POST', `/api/admin/game/inventories/${commonInventoryId}/create-item`,
    {userId, predefinedPerks}
  );
}

export async function getUserInfoItem(inventoryId: string, userId: string):
  Promise<AxiosResponse<{ perksData: IPredefinedPerk[] }>> {
  return makeAdminRequest('GET', `/api/admin/game/users/${userId}/inventories/${inventoryId}/perks`);
}

export async function createUserInfoItem(inventoryId: string, userId: string, predefinedPerks: any[]) {
  return makeAdminRequest('POST', `/api/admin/game/users/${userId}/inventories/${inventoryId}/perks`,
    {predefinedPerks}
  );
}