import styled, {css} from 'styled-components';

const determineWrapperStyles = (childrenCount: number) => {
  if (childrenCount <= 2) {
    return css`
            flex-direction: row;
            flex-wrap: nowrap;
            gap: 8px;
        `;
  } else if (childrenCount === 3) {
    return css`
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 8px;
        `;
  } else {
    return css`
            flex-direction: row;
            flex-wrap: wrap;
            gap: 8px 0;

            & > * {
                flex: 1 1 50%;
            }
        `;
  }
};

export const Wrapper = styled.div<{ childrenCount: number }>`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({childrenCount}) => determineWrapperStyles(childrenCount)}
`;