import React, {useEffect, useRef, useState} from 'react';

import Modal from '../../../../Modal';
import GrantGiftModalContent from '../../GrantGiftModalContent/GrantGiftModalContent';
import ActionConfirmationModalContent
  from '../../../../common/ActionConfirmationModalContent/ActionConfirmationModalContent';
import CustomItemsCreation from '../../CustomItemsCreation/CustomItemsCreation';

import TextOrNumberInput from '../../../../ModalUtils/TextOrNumberInput/TextOrNumberInput';
import CustomSelect from '../../../../ModalUtils/CustomSelect/CustomSelect';
import ButtonGroup from '../../../../../Admin/ButtonGroup/ButtonGroup';
import CustomAsyncSelect from '../../../../ModalUtils/CustomAsyncSelect/CustomAsyncSelect';

import Loader from '../../../../../common/Loader/ContainerLoader';
import ActionButton from '../../../../../Admin/ActionButton/ActionButton';
import TableActions from '../../../../../Admin/TableActions/TableActions';
import Field from '../../../../ModalUtils/Field/Field';

import {bool} from '../../../../../../utils/data/td';

import API from '../../../../../../api';
import UserInfoById from '../../../../../../types/api/Admin/Users/UserInfoById';
import IUserInventory, {InfoFilterBodyRequest} from '../../../../../../types/api/Admin/Users/UserInventory';
import IInventoryCategories from '../../../../../../types/api/Admin/Game/InventoryCategories';
import IDropdownResultsByName from '../../../../../../types/api/Admin/ReferralRewards/DropdownResults';
import IDropdownResults from '../../../../../../types/api/Admin/GameSessions/DropdownResults';
import IInventoryTypes from '../../../../../../types/api/Admin/Game/InventoryTypes';

import {
  OverflowContainer,
  TableBody,
  TableHead,
  TableTd,
  TableTh,
  TableTr,
  WrapperTable,
} from '../../../../../../pages/Admin/styles';
import {ModalWrapper, ModalTitle, ModalRow, ModalText} from '../../../../ModalUtils/styles';
import {ImageUrl} from '../../../../../../pages/Admin/styles';

let inputChangedTimeout: NodeJS.Timeout;
const INPUT_CHANGE_APPLY_MILLIS = 1500; // 1.5 sec

interface IUserInventoryPage {
  userInfo: UserInfoById;
  userId: string;
  rarityTypes: IInventoryCategories[];
  sectionTypes: IInventoryCategories[];
  rarityLevels: IInventoryCategories[];
  inventoryTypes: IInventoryTypes[];
}

const UserInventory = ({
  userInfo,
  userId,
  rarityTypes,
  sectionTypes,
  rarityLevels,
  inventoryTypes
}: IUserInventoryPage) => {
  const [userInventory, setUserInventory] = useState<IUserInventory[]>([]);

  const [loaded, setLoaded] = useState<boolean>(false);

  const [selectedInventoryId, setSelectedInventoryId] = useState<string>('');

  const [grantModalVisible, setGrantModalVisible] = useState<boolean>(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);

  const [filterInputValues, setFilterInputValues] = useState<InfoFilterBodyRequest>({isAsset: 'all'});
  const [filterValues, setFilterValues] = useState<InfoFilterBodyRequest>({isAsset: 'all'});

  const [customItemsCreationVisible, setCustomItemsCreationVisible] = useState<boolean>(false);
  const [selectedCustomItem, setSelectedCustomItem] = useState<{
    rarity: number, isNft: boolean, category: number | null, selectedItemId: string
  } | null>(null);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const changeHandler = (field: keyof InfoFilterBodyRequest, value: any) => {
    setFilterInputValues((prev) => {
      const newInputName = {...prev, [field]: value};

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setFilterValues((prevFilters) => ({...prevFilters, ...newInputName}));
      }, INPUT_CHANGE_APPLY_MILLIS);

      return newInputName;
    });
  };

  const getData = async (filter: InfoFilterBodyRequest, refreshLoading: boolean = true) => {
    if (refreshLoading) {
      setLoaded(false);
    }

    const {typeName, commonName, collectionName, ...parsedFilter} = filter;

    try {
      const response = await API.admin.inventories.getUserInventory(userId, parsedFilter);
      if (response) {
        setUserInventory(response.data.data);
      }
    } finally {
      setLoaded(true);
    }
  };

  const deleteInventory = async () => {
    try {
      await API.admin.inventories.deleteUserInventory(userId, selectedInventoryId);
      await getData(filterValues).catch(console.error);
      window.addToast('success', '', 'Inventory deleted', 1500, '#1FBF42');
    } catch (e: any) {
      window.addToast('danger', 'Error', `${e ? e.message : 'Failed. Please try again later'}`);
    } finally {
      setDeleteModalVisible(false);
      setSelectedInventoryId('');
    }
  };

  const parseData = (data: (IDropdownResultsByName | IDropdownResults)[]) => {
    return data.map(item => ({
      value: item.id,
      label: 'name' in item ? item.name : item.email
    }));
  };

  const loadInventoryOptions = (inputValue: string, callback: (values: any[]) => void) => {
    if (!inputValue) {
      return;
    }
    if (inputChangedTimeout) {
      clearTimeout(inputChangedTimeout);
    }
    inputChangedTimeout = setTimeout(async () => {
      const response = await API.admin.levelRewards.searchInventoryByName(inputValue, filterInputValues?.rarity);
      if (!response) {
        return;
      }
      callback(parseData(response.data));
    }, INPUT_CHANGE_APPLY_MILLIS);
  };

  const loadCollectionOptions = (inputValue: string, callback: (values: any[]) => void) => {
    if (!inputValue) {
      return;
    }
    if (inputChangedTimeout) {
      clearTimeout(inputChangedTimeout);
    }
    inputChangedTimeout = setTimeout(async () => {
      const response = await API.admin.collections.searchCollections(inputValue, filterInputValues?.rarity);
      if (!response) {
        return;
      }
      callback(parseData(response.data));
    }, INPUT_CHANGE_APPLY_MILLIS);
  };

  const loadInventoryTypeOptions = (inputValue: string, callback: (values: any[]) => void) => {
    if (!inputValue) {
      return;
    }
    if (inputChangedTimeout) {
      clearTimeout(inputChangedTimeout);
    }
    inputChangedTimeout = setTimeout(async () => {
      const response = await API.admin.inventoryTypes.searchInventoryType(inputValue);
      if (!response) {
        return;
      }
      callback(parseData(response.data));
    }, INPUT_CHANGE_APPLY_MILLIS);
  };

  useEffect(() => {
    getData(filterValues).catch(console.error);
  }, [filterValues]);

  return (
    <ModalWrapper style={{width: '800px'}}>
      <ModalTitle className="title-bold" marginBottom={8}>
        {userInfo?.username || 'Username'}
      </ModalTitle>
      <ModalText
        marginBottom={20} className="title-bold"
        style={{width: '100%', textAlign: 'center'}}
      >
        Inventory
      </ModalText>
      <ModalRow>
        <ActionButton
          onClick={(event) => {
            event.stopPropagation();
            setGrantModalVisible(true);
          }}
          icon={7}
        />
      </ModalRow>

      <ModalRow style={{gap: 8}}>
        <Field title="Rarity:" style={{margin: 0}}>
          <CustomSelect
            options={rarityTypes.map((rarity) => ({value: rarity?.id ?? 0, label: rarity.name}))}
            onChange={(e) => changeHandler('rarity', e?.value >= 0 ? Number(e.value) : undefined)}
            value={(filterInputValues?.rarity && filterInputValues?.rarity >= 0) ? {
              value: filterInputValues.rarity,
              label: rarityTypes.find(rarityType => rarityType.id === filterInputValues.rarity)?.name || ''
            } : undefined}
            placeholder="Rarity ..."
            isClearable={true}
            style={{margin: 0}}
          />
        </Field>
        <Field title="Section:" style={{margin: 0}}>
          <CustomSelect
            options={sectionTypes.map((section) => ({value: section?.id ?? 0, label: section.name}))}
            onChange={(e) => changeHandler('section', e?.value >= 0 ? Number(e.value) : undefined)}
            value={(filterInputValues?.section && filterInputValues?.section >= 0) ? {
              value: filterInputValues.section,
              label: sectionTypes.find(sectionType => sectionType.id === filterInputValues.section)?.name || ''
            } : undefined}
            placeholder="Section ..."
            isClearable={true}
            style={{margin: 0}}
          />
        </Field>
        <Field title="Issued Id:" style={{margin: 0}}>
          <TextOrNumberInput
            value={filterInputValues.issuedId ?? undefined}
            onChange={(e) => changeHandler('issuedId',
              e.target.value ? Math.max(Number(e.target.value), 1) : undefined)}
            placeholder="Issued Id ..."
            type="number"
            style={{margin: 0}}
          />
        </Field>
        <Field title="Is Asset:" style={{margin: 0, alignItems: 'flex-start', width: 'auto'}}>
          <ButtonGroup
            group="visible-group" label={filterInputValues.isAsset}
            onChange={(e) => changeHandler('isAsset', e)}
          />
        </Field>
      </ModalRow>
      <ModalRow style={{gap: 8}}>
        <Field title="Inventory Type:" style={{margin: 0}}>
          <CustomAsyncSelect
            loadOptions={loadInventoryTypeOptions}
            value={filterInputValues?.typeId ? {
              value: filterInputValues.typeId,
              label: filterInputValues.typeName ?? '',
            } : undefined}
            onChange={(e) => {
              changeHandler('typeId', e?.value ? e.value : undefined);
              changeHandler('typeName', e?.value ? e.label : undefined);
            }}
            marginBottom={0}
            isClearable={true}
          />
        </Field>
        <Field title="Inventory:" style={{margin: 0}}>
          <CustomAsyncSelect
            loadOptions={loadInventoryOptions}
            value={filterInputValues?.commonId ? {
              value: filterInputValues.commonId,
              label: filterInputValues.commonName ?? '',
            } : undefined}
            onChange={(e) => {
              changeHandler('commonId', e?.value ? e.value : undefined);
              changeHandler('commonName', e?.value ? e.label : undefined);
            }}
            marginBottom={0}
            isClearable={true}
          />
        </Field>
        <Field title="Collection:" style={{margin: 0}}>
          <CustomAsyncSelect
            loadOptions={loadCollectionOptions}
            value={filterInputValues?.collectionId ? {
              value: filterInputValues.collectionId,
              label: filterInputValues.collectionName ?? '',
            } : undefined}
            onChange={(e) => {
              changeHandler('collectionId', e?.value ? e.value : undefined);
              changeHandler('collectionName', e?.value ? e.label : undefined);
            }}
            marginBottom={0}
            isClearable={true}
          />
        </Field>
      </ModalRow>
      <ModalRow marginBottom={0} style={{alignItems: 'flex-start', minHeight: '405px'}}>
        <OverflowContainer>
          <WrapperTable>
            <TableHead>
              <TableTr>
                <TableTh>Name</TableTh>
                <TableTh>NFT</TableTh>
                <TableTh>Image</TableTh>
                <TableTh>Actions</TableTh>
              </TableTr>
            </TableHead>
            <TableBody>
              {loaded ? (
                <>
                  {userInventory.length > 0 ? (
                    <>
                      {userInventory.map((inventory, index) => (
                        <TableTr key={inventory?.id || index.toString()} active={true}>
                          <TableTd>{inventory?.name || '-'}</TableTd>
                          {bool(Boolean(inventory.assetId))}
                          <TableTd>
                            {inventory.imageUrl ?
                              <ImageUrl
                                src={inventory.imageUrl}
                                alt={`imageUrl-${index}`}
                                style={{display: 'inline-flex'}}/> : '-'}
                          </TableTd>
                          <TableTd>
                            <TableActions>
                              <ActionButton
                                onClick={() => {
                                  setSelectedCustomItem({
                                    rarity: inventory.rarity, isNft: Boolean(inventory.assetId),
                                    category: inventoryTypes.find((type) => type.id === inventory.typeId)?.category ?? null,
                                    selectedItemId: inventory.id,
                                  });
                                  setCustomItemsCreationVisible(true);
                                }}
                                noteText="Change"
                                disabled={Boolean(inventory.section !== 0)}
                                zIndex={1000 - index - 2}
                              />
                              <ActionButton
                                onClick={() => {
                                  setSelectedInventoryId(inventory?.id);
                                  setDeleteModalVisible(true);
                                }}
                                noteText="Delete" color={1} icon={1}
                                disabled={Boolean(inventory.assetId)}
                                zIndex={1000 - index - 1}
                              />
                            </TableActions>
                          </TableTd>
                        </TableTr>
                      ))}
                    </>
                  ) : (
                    <TableTr>
                      <TableTd colSpan={4} style={{textAlign: 'center', height: '63px'}}>
                        No users found.
                      </TableTd>
                    </TableTr>
                  )}
                </>
              ) : (
                <TableTr>
                  <TableTd colSpan={4} style={{textAlign: 'center', height: '63px'}}>
                    <Loader/>
                  </TableTd>
                </TableTr>
              )}
            </TableBody>
          </WrapperTable>
        </OverflowContainer>
      </ModalRow>

      <Modal
        isOpen={grantModalVisible}
        onClose={() => setGrantModalVisible(false)}
      >
        <GrantGiftModalContent
          onClose={() => setGrantModalVisible(false)}
          itemId={userId}
          grantFunction={API.admin.inventories.grantInventoryForUserInfo}
          searchFunction={API.admin.inventories.searchInventoriesByName}
          modalTitle="Grant Inventory"
          getData={() => getData(filterValues)}
        />
      </Modal>

      {selectedInventoryId && <Modal
        isOpen={deleteModalVisible}
        onClose={() => setDeleteModalVisible(false)}
      >
        <ActionConfirmationModalContent
          onClose={() => setDeleteModalVisible(false)}
          actionConfirmation={deleteInventory}

          modalTitle={'Delete Inventory'}
          modalText={'Are you sure that you want to delete this Inventory?'}
          buttonTitle={'Delete'}
        />
      </Modal>}

      {selectedCustomItem && (
        <Modal
          isOpen={customItemsCreationVisible}
          onClose={() => setCustomItemsCreationVisible(false)}
        >
          <CustomItemsCreation
            onClose={() => setCustomItemsCreationVisible(false)}
            rarity={selectedCustomItem.rarity}
            isNft={selectedCustomItem.isNft}
            category={selectedCustomItem.category}
            selectedItemId={selectedCustomItem.selectedItemId}
            rarityTypes={rarityTypes}
            getData={() => getData(filterValues)}
            submitFunction={API.admin.inventories.createUserInfoItem}
            rarityLevels={rarityLevels}
            userId={userId}
          />
        </Modal>
      )}
    </ModalWrapper>
  );
};
export default UserInventory;