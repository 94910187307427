import React, {ReactNode, Children} from 'react';

import {Wrapper} from './styles';

interface ITableActions {
    children: ReactNode,
}

const TableActions = ({children}: ITableActions) => {
  // React.Children.forEach(children, (child: ReactNode) => {
//   console.log((child as ReactElement).props)
// });
  const childrenCount = Children.count(children);

  return (
    <Wrapper childrenCount={childrenCount}>
      {children}
    </Wrapper>
  );
};

export default TableActions;